import { LOG_LEVEL } from '@/config/environment';
/** Signature of a logging function */

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const NO_OP = function (_message) {
  return undefined;
};
/** Logger which outputs to the browser console */


export class ConsoleLogger {
  constructor(options) {
    this.log = void 0;
    this.info = void 0;
    this.warn = void 0;
    this.error = void 0;
    const {
      level
    } = options || {};

    if (level === 'none') {
      this.error = NO_OP;
      this.warn = NO_OP;
      this.log = NO_OP;
      this.info = NO_OP;
      return;
    }

    this.error = console.error.bind(console);

    if (level === 'error') {
      this.warn = NO_OP;
      this.log = NO_OP;
      return;
    }

    this.warn = console.warn.bind(console);

    if (level === 'warn') {
      this.log = NO_OP;
      this.info = NO_OP;
      return;
    }

    this.log = console.log.bind(console);
    this.info = console.log.bind(console);
  }

}
export default new ConsoleLogger({
  level: LOG_LEVEL
});