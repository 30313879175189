const BASE_URL = `${process.env.NEXT_PUBLIC_BASE_URL || ''}`.replace(/\/$/, '');
export const LOG_LEVEL = process.env.NEXT_PUBLIC_LOG_LEVEL || 'warn';
export default {
  baseUrl: BASE_URL,
  logLevel: LOG_LEVEL,
  ignoreConsoleErrors: [/defaultProps/, /Warning: React does not recognize the `%s` prop on a DOM element. If you intentionally want it to appear in the DOM as a custom attribute, spell it as lowercase `%s` instead/ // Remove `fetchPriority` warning
  ],
  ignoreConsoleWarnings: [/\[redi\]: Expect 0 custom parameter\(s\)/, /univer\.sheet\.cell-alert already exists/],
  ignoreUnhandledErrors: [/Cannot find "univer\.doc\.text-selection-render-manager"/],
  isDevelopment: false,
  isStaging: `${process.env.NEXT_PUBLIC_BASE_URL || ''}`.search('//staging.') > -1,
  isProduction: `${process.env.NEXT_PUBLIC_BASE_URL || ''}`.search('//excelkits.com') > -1,
  defaultSocialImg: `${BASE_URL}/img/social/og-image-default.jpg`
};