const defaults = {
  productName: 'Excelkits',
  productNameSpace: 'exk',
  productUrl: process.env.NEXT_PUBLIC_BASE_URL,
  externalEmail: 'matt@industriousapps.com',
  description: 'Turn your Excel into lead magnets, calculators, quizzes, sales tools, products and more',
  companyName: 'Industrious Apps, LLC',
  mail: {
    streetAddress: '750 N Saint Paul St Ste 250',
    city: 'Dallas',
    state: 'Texas',
    zip: '75201-3206',
    country: 'USA'
  },
  legalLinks: [{
    name: 'Privacy Policy',
    href: '/privacy-policy'
  }, {
    name: 'Terms & Conditions',
    href: '/terms-and-conditions'
  }],
  footerLinks: [{
    title: 'Tools',
    links: [{
      name: 'Treadmill Pace',
      href: '/treadmill-pace-calculator'
    }, {
      name: 'Swim Pace',
      href: '/swim-pace-calculator'
    }, {
      name: 'Pie Chart',
      href: '/pie-chart-maker'
    }, {
      name: 'Scatter Plot',
      href: '/scatter-plot-maker'
    }, {
      name: 'Donut Chart',
      href: '/donut-chart-maker'
    }, {
      name: 'Bubble Chart',
      href: '/bubble-chart-maker'
    }]
  }, {
    title: 'Company',
    links: [{
      name: 'About',
      href: '/about'
    }, {
      name: 'Authors',
      href: '/authors'
    }, {
      name: 'Contact',
      href: '/contact'
    }]
  }, {
    title: 'Resources',
    links: [{
      name: 'Pricing',
      href: '/pricing'
    }, {
      name: 'Templates',
      href: '/templates'
    }]
  }, {
    title: 'Social',
    links: [{
      name: 'Instagram',
      href: 'https://www.instagram.com/excelkits/',
      target: '_blank'
    }, {
      name: 'Threads',
      href: 'https://www.threads.net/@excelkits',
      target: '_blank'
    }, {
      name: 'X / Twitter',
      href: 'https://x.com/excelkits',
      target: '_blank'
    }, {
      name: 'Pinterest',
      href: 'https://www.pinterest.com/excelkits/',
      target: '_blank'
    }, {
      name: 'LinkedIn',
      href: 'https://www.linkedin.com/company/excelkits',
      target: '_blank'
    }]
  }],
  demoLinks: {},
  // TODO: migrate to config/design
  colors: {
    bluePrimary: '#004de5',
    bodyFontColor: '#12131a',
    gray60: '#696C80',
    gray100: '#12131a'
  }
};
export default defaults; // Used by some form elements
// that require custom validation

export const customFormValidityMethod = `${defaults.productNameSpace}CheckValidity`;