class BaseError extends Error {
  constructor(message) {
    super(message);
    this.errors = void 0;
    this.name = 'Error';
    this.errors = [];
    Object.setPrototypeOf(this, new.target.prototype);
  } // Populate error list


  addErrors(errors) {
    if (!Array.isArray(errors)) {
      return;
    }

    errors.forEach(err => {
      if (!err || typeof err !== 'object') {
        return;
      }

      this.errors.push({
        name: err.source && err.source.pointer ? `${err.source.pointer}` : 'unknown',
        title: err.title ? `${err.title}` : '',
        detail: err.detail ? `${err.detail}` : ''
      });
    });
  }

  toString() {
    const errorsMsgs = Array.isArray(this.errors) ? this.errors.map(_ref => {
      let {
        title,
        detail
      } = _ref;
      return [title, detail].filter(Boolean);
    }).join(', ') : '';
    return `${this.message || this.name}${errorsMsgs ? ': ' : ''}${errorsMsgs}`;
  }

}

export default BaseError;