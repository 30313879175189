import envConfg from '@/config/environment';
let isSetup = false;
const originalConsoleWarn = console.warn;
const originalConsoleError = console.error;
const IGNORE_ERRORS = envConfg.ignoreConsoleErrors || [];
const IGNORE_WARNINGS = envConfg.ignoreConsoleWarnings || [];
export default function useSilenceConsole() {
  if (!isSetup) reconfigureConsole();
}

function reconfigureConsole() {
  isSetup = true;

  console.error = function () {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    if (typeof args[0] === 'string' && IGNORE_ERRORS.some(errRegExp => errRegExp.test(args[0]))) {
      return;
    }

    originalConsoleError(...args);
  };

  console.warn = function () {
    for (var _len2 = arguments.length, args = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
      args[_key2] = arguments[_key2];
    }

    if (typeof args[0] === 'string' && IGNORE_WARNINGS.some(errRegExp => errRegExp.test(args[0]))) {
      return;
    }

    originalConsoleWarn(...args);
  };
}