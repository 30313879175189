import BaseError from './baseError';

class ErrorUserAction extends BaseError {
  constructor(message, originalErr) {
    super(message);
    // Identifier for error
    this.code = void 0;
    // User facing message about error
    this.userMessage = void 0;
    this.name = 'ErrorUserAction';
    Object.setPrototypeOf(this, new.target.prototype);

    if (originalErr && originalErr.stack) {
      this.stack = originalErr.stack;
    }
  }

}

export default ErrorUserAction;