import _defineProperty from "/Users/matt/dev/github.com/industriousapps/excelkits-client/node_modules/.pnpm/next@13.5.7_@babel+core@7.25.2_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.79.3/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _objectWithoutProperties from "/Users/matt/dev/github.com/industriousapps/excelkits-client/node_modules/.pnpm/next@13.5.7_@babel+core@7.25.2_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.79.3/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
const _excluded = ["credential"];

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { auth, googleAuthProvider } from '@/config/firebase';
import { utils as utilsCore } from '@industriousapps/excelkits-core';
const PREFIX = 'google: cloud: services:';
const API_DOMAIN = process.env.NEXT_PUBLIC_FIREBASE_FUNCTIONS_DOMAIN;
const {
  createWrappedError
} = utilsCore;
// Initiate a sign in request with a google auth popup
export function signInWithPopup() {
  return auth.signInWithPopup(googleAuthProvider).then(_ref => {
    let {
      credential
    } = _ref,
        userCredential = _objectWithoutProperties(_ref, _excluded);

    if (!credential) {
      throw Error(`signInWithPopup: missing credential`);
    }

    const oAuthCredential = credential;
    return _objectSpread(_objectSpread({}, userCredential), {}, {
      credential: oAuthCredential,
      accessToken: (oAuthCredential === null || oAuthCredential === void 0 ? void 0 : oAuthCredential.accessToken) || ''
    });
  });
} // Use a Google Auth code to request
// an access token for a user

export async function googleCloudToken(_ref2) {
  let {
    userUid,
    code
  } = _ref2;
  let response;

  try {
    response = await fetch(`${API_DOMAIN}/api/v0/google/token/${userUid}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        code
      })
    });
  } catch (err) {
    throw createWrappedError(`${PREFIX} googleCloudToken: request failure`, err);
  }

  let responseData;

  try {
    responseData = await response.json();
  } catch (parseError) {
    throw createWrappedError(`${PREFIX} googleCloudToken: failed to parse JSON`, parseError);
  }

  if (!response.ok) {
    var _responseData, _responseData$errors, _responseData$errors$;

    const errorMessage = ((_responseData = responseData) === null || _responseData === void 0 ? void 0 : (_responseData$errors = _responseData.errors) === null || _responseData$errors === void 0 ? void 0 : (_responseData$errors$ = _responseData$errors[0]) === null || _responseData$errors$ === void 0 ? void 0 : _responseData$errors$.detail) || 'Authorization error';
    throw new Error(`${PREFIX} googleCloudToken: failed response: ${errorMessage}`);
  }

  return responseData.data;
} // Request to use a refresh token
// to generate a new access token

export async function googleCloudTokenRefresh(_ref3) {
  let {
    userUid,
    refreshToken
  } = _ref3;
  let response;

  try {
    response = await fetch(`${API_DOMAIN}/api/v0/google/token/refresh/${userUid}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        refreshToken
      })
    });
  } catch (err) {
    throw createWrappedError(`${PREFIX} googleCloudTokenRefresh: request failed`, err);
  }

  let responseData;

  try {
    responseData = await response.json();
  } catch (parseError) {
    throw createWrappedError(`${PREFIX} googleCloudTokenRefresh: failed to parse JSON`, parseError);
  }

  if (!response.ok) {
    var _responseData2, _responseData2$errors, _responseData2$errors2;

    const errorMessage = ((_responseData2 = responseData) === null || _responseData2 === void 0 ? void 0 : (_responseData2$errors = _responseData2.errors) === null || _responseData2$errors === void 0 ? void 0 : (_responseData2$errors2 = _responseData2$errors[0]) === null || _responseData2$errors2 === void 0 ? void 0 : _responseData2$errors2.detail) || 'Authorization error';
    throw Error(`${PREFIX} googleCloudTokenRefresh: failed response: ${errorMessage}`);
  }

  return responseData.data;
} // Request to Google API's to see if an
// access token is still valid or has expired

export async function checkAccessToken(_ref4) {
  let {
    accessToken
  } = _ref4;
  let response;

  try {
    response = await fetch(`https://www.googleapis.com/oauth2/v3/tokeninfo?access_token=${accessToken}`, {
      method: 'GET'
    });
  } catch (err) {
    throw createWrappedError(`${PREFIX} checkAccessToken: googleapis request failed`, err);
  }

  let responseData;

  try {
    responseData = await response.json();
  } catch (parseError) {
    throw createWrappedError(`${PREFIX} checkAccessToken: failed to parse JSON`, parseError);
  }

  if (!response.ok) {
    return false;
  }

  return Boolean(responseData);
}