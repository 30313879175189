import envConfg from '@/config/environment';
let isSetup = false;
const IGNORE_ERRORS = envConfg.ignoreUnhandledErrors || [];
export default function useSilenceError() {
  if (!isSetup) initializeEvent();
}

function initializeEvent() {
  if (false) return;
  isSetup = true;

  window.onerror = msg => {
    if (typeof msg === 'string' && IGNORE_ERRORS.some(errRegExp => errRegExp.test(msg))) {
      return true;
    }
  };
}